// @flow

import { FEATURE_TOUR_SETTINGS } from 'qap/constants'

const getDefaultFeatureTours = () =>
  Object.fromEntries<string, null>(
    Object.keys(FEATURE_TOUR_SETTINGS)
      .filter((key) => FEATURE_TOUR_SETTINGS[key])
      .map((key) => [key, null]),
  )

const DEFAULT_FEATURE_TOURS = getDefaultFeatureTours()

export { DEFAULT_FEATURE_TOURS }
