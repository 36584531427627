// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authActions } from 'state/auth'
import { AuthProvider } from 'contexts/auth'
import { API_ROUND_ENROLMENTS_INTERNAL_SUBMISSIONS_CREATE } from 'state/roundEnrolment/actions'

import { LOGIN_URL } from 'qap/constants'
import getRedirect from 'utilities/getRedirect'
import { authorizationSelector, Permissions } from 'api'
import { isFeatureActive } from 'api/features'
import { isExpired } from 'utilities/isExpired'

import type {
  Feature,
  User,
  Organisation
} from 'types'

type Props = {
  isAuthenticated: boolean,
  user: User,
  authorization: Object,
  permissions: Array<string>,
  loginFailures: number,
  expiry: string,
  fetchSessionInfo: () => void,
  expireSession: (location?: Object) => void,
  features: Feature[],
  logoutUser: () => void,
  render: Function,
  location: Object,
  organisations: Organisation[],
  canSwitchOrganisations: boolean,
  canViewAnalytics: boolean,
  fetchOrganisations: () => void,
  submitting: boolean,
  clientSettings: Object,
  children: any
}

const recordLocationThenRedirectToLogin = () => {
  // We are writing to the history api to record the location
  // then triggering a hard refresh
  let { location, history } = window

  if (location.pathname !== LOGIN_URL) {
    if (!history.pushState) {
      // pushState has a lot of support, but in case it doesn't...
      // we'll do a crude redirect
      location = LOGIN_URL
    } else {
      history.pushState({
        state: {
          redirect: getRedirect(location)
        }
      }, 'Login', LOGIN_URL)

      // Need to trigger a reload (as our url is now set to LOGIN_URL)
      // as mutating the window location will cause another entry in the history api
      location.reload(true)
    }
  }

  return null
}

class PrivateRoute extends Component<Props> {
  componentDidMount () {
    const { isAuthenticated, fetchSessionInfo } = this.props

    if (!isAuthenticated) return

    fetchSessionInfo()
  }

  isFeatureActive = (feature: string): boolean => isFeatureActive({ features: this.props.features, feature })

  canMakeInternalSubmission = (): boolean => {
    const { authorization } = this.props
    return Permissions.isOperationPermitted(authorization, API_ROUND_ENROLMENTS_INTERNAL_SUBMISSIONS_CREATE)
  }

  checkExpiry (prevProps: Props) {
    const { expiry, expireSession, location } = this.props
    if (prevProps.location === location) return

    if (isExpired(expiry)) {
      expireSession(location)
    }
  }

  componentDidUpdate (prevProps: Props) {
    this.checkExpiry(prevProps)
  }

  render () {
    const {
      isAuthenticated,
      user,
      features,
      authorization,
      logoutUser,
      children,
      canSwitchOrganisations,
      organisations = [],
      fetchSessionInfo,
      canViewAnalytics,
      submitting,
      clientSettings,
    } = this.props

    if (!isAuthenticated) {
      return recordLocationThenRedirectToLogin()
    }

    const organisationIds = organisations.map(({ id }) => id).toString()

    // TODO: Move more auth props into the AuthProvider
    return (
      <AuthProvider
        value={{
          organisationIds,
          features,
          user,
          isFeatureActive: this.isFeatureActive,
          authorization,
          logoutUser,
          canMakeInternalSubmission: this.canMakeInternalSubmission(),
          canSwitchOrganisations,
          selectedOrganisations: organisations,
          fetchSessionInfo,
          canViewAnalytics,
          submitting,
          clientSettings,
        }}
      >
        <div key={organisationIds}>
          { children }
        </div>
      </AuthProvider>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    auth: {
      token,
      user,
      features,
      permissions,
      loginFailures,
      expiry,
      canSwitchOrganisations,
      organisations,
      canViewAnalytics,
      submitting,
      clientSettings,
    }
  } = state
  const authorization = authorizationSelector(state)

  return {
    isAuthenticated: (token && (token.length > 0)),
    user,
    features,
    permissions,
    loginFailures,
    expiry,
    authorization,
    canSwitchOrganisations,
    organisations,
    canViewAnalytics,
    submitting,
    clientSettings,
  }
}

const mapDispatchToProps = {
  fetchSessionInfo: authActions.fetchSessionInfo,
  logoutUser: authActions.logoutUser,
  expireSession: authActions.expireSession,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
