// @flow

import type { ClientSettings } from 'state/types'

export const CLIENT_SETTING_NAMESPACE_SEPARATOR = '.'

export const CLIENT_SETTING_NAMESPACES = {
  cookies: 'cookies',
  featureTours: 'featureTours',
  dashboard: 'dashboard',
}

const isString = (value: any) => typeof value === 'string'

const destructureKey = (key: string) => {
  if (!isString(key)) return { namespace: '', keyName: '' }

  const [namespace, ...rest] = key.split(CLIENT_SETTING_NAMESPACE_SEPARATOR)

  return { namespace, keyName: rest.join(CLIENT_SETTING_NAMESPACE_SEPARATOR) }
}

export const buildNamespaceFilter = (namespace: string) => (key: string) =>
  isString(key) && destructureKey(key).namespace === namespace

export const groupedClientSettingsToObject = (
  clientSettings: ClientSettings,
  namespace: string,
) => {
  if (!clientSettings || Object.keys(clientSettings).length <= 0) return {}

  return Object.keys(clientSettings)
    .filter(buildNamespaceFilter(namespace))
    .reduce((acc, key) => {
      acc[destructureKey(key).keyName] = clientSettings[key]
      return acc
    }, {})
}
