// @flow

import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateClientSettings } from 'state/auth/actions'

export const useClientSettings = (key: string) => {
  const dispatch = useDispatch()
  const clientSettings = useSelector((state) => state.auth?.clientSettings) || {}

  const updateSettings = useCallback(
    <T>(value: T) => dispatch(updateClientSettings({ key, value })),
    [key]
  )

  return {
    value: clientSettings[key],
    allSettings: clientSettings,
    updateSettings,
  }
}

export default useClientSettings
