// @flow
import { decamelizeKeys } from 'humps'
import { all, fork, put, takeLatest } from 'redux-saga/effects'

import * as actions from './actions'
import * as APIActions from '../api/actions'
import { passwordResetActions } from 'state/passwordReset'
import { signupActions } from 'state/signup'
import history from 'state/history'
import { setParams } from 'utilities/apiQueryString'
import { requestActions } from 'state/request'
import { failure, success } from 'utilities/actions'
import getRedirect from 'utilities/getRedirect'

import { API_URL, CLIENT_SETTINGS_ENDPOINT, LANDING_PAGE_URL, LOGIN_URL } from 'qap/constants'

function* expireSession(action): Generator<any, any, any> {
  const { location } = action

  yield put(actions.logoutUser())
  yield window.history.pushState(
    {
      state: {
        redirect: getRedirect(location || window.location),
        message: 'Your session has expired. Please login again.',
      },
    },
    'Login',
    LOGIN_URL,
  )

  // Hard refresh to potentially to avoid having a stale version of the app
  yield (window.location = LOGIN_URL)
}

function* fetchApiSpecification(action): Generator<any, any, any> {
  yield put(APIActions.fetchApiSpecification())
}

function * fetchSystemStatus (action) : Generator<any, any, any> {
  yield put(APIActions.fetchSystemStatus())
}

function* onAuthenticated(action): Generator<any, any, any> {
  yield fetchApiSpecification(action)
  yield fetchSystemStatus(action)
}

function* onJwtAuthenticated(action): Generator<any, any, any> {
  const { data: { secureId } = {}, redirect, notification } = action

  if (secureId) {
    history.replace(`/create-account/${secureId}${setParams({ redirect, notification })}`)
  } else {
    yield put({
      ...action,
      type: success(actions.API_SESSION_PASSWORD_AUTH_CREATE),
    })

    history.replace(redirect || LANDING_PAGE_URL)
  }
}

function* watchAuthSuccess(): Generator<any, any, any> {
  yield takeLatest(
    [
      success(actions.API_SESSION_PASSWORD_AUTH_CREATE),
      success(signupActions.API_SIGNUPS_CREATE),
      success(passwordResetActions.API_PASSWORD_RESETS_RESETS_CREATE),
    ],
    onAuthenticated,
  )
  yield takeLatest([success(actions.API_SESSION_JWT_AUTH_CREATE)], onJwtAuthenticated)
  yield takeLatest([actions.AUTH_EXPIRE_SESSION], expireSession)
}

function* updateClientSettings(action): Generator<any, any, any> {
  const {
    payload: { key, value },
  } = action
  const body = { data: decamelizeKeys({ [key]: value }) }

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PATCH',
    url: `${API_URL}${CLIENT_SETTINGS_ENDPOINT}`,
    body,
    successAction: success(actions.API_SESSION_CLIENT_SETTINGS_UPDATE),
    failureAction: failure(actions.API_SESSION_CLIENT_SETTINGS_UPDATE),
  })
}

export default function* authSagas(): Generator<any, any, any> {
  yield all([
    fork(watchAuthSuccess),
    takeLatest(actions.API_SESSION_CLIENT_SETTINGS_UPDATE, updateClientSettings),
  ])
}
