// @flow

import { useClientSettings } from 'hooks/clientSettings'
import { CLIENT_SETTING_NAMESPACES } from 'utilities/clientSettings'
import { useContext } from 'react'
import { AuthContext } from 'contexts/auth'

const useCookieSettings = () => {
  const { submitting } = useContext(AuthContext)
  const cookieAcceptedSettingKey = `${CLIENT_SETTING_NAMESPACES.cookies}.accepted`
  const { value: cookiesAccepted, updateSettings: updateCookiesSetting } =
    useClientSettings(cookieAcceptedSettingKey)

  return {
    submitting,
    cookiesAccepted,
    updateCookiesSetting,
  }
}

export default useCookieSettings
