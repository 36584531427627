// @flow
import { DOC_TYPES } from 'qap/constants'
import { normalizeCommentText } from 'utilities/reportComment'
import type { CommentState } from 'types'
import type { ActionOptions } from './types'

export const FETCH_REPORT = 'FETCH_REPORT'
export const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA'
export const CLEAR_REPORT_ERRORS = 'CLEAR_REPORT_ERRORS'
export const APPLY_REPORT_COMMENT_FILTER = 'APPLY_COMMENT_FILTER'
export const SET_REPORT_COMMENT_STATE = 'REPORT_COMMENT_STATE'
export const CLEAR_REPORT_COMMENT_STATE = 'CLEAR_REPORT_COMMENT_STATE'
export const API_REPORTS_ATTACHMENTS_CREATE = 'API_REPORTS_ATTACHMENTS_CREATE'
export const API_REPORTS_ATTACHMENTS_UPDATE = 'API_REPORTS_ATTACHMENTS_UPDATE'
export const API_REPORTS_ATTACHMENTS_DESTROY = 'API_REPORTS_ATTACHMENTS_DESTROY'
export const API_REPORTS_COMMENTS_CREATE = 'API_REPORTS_COMMENTS_CREATE'
export const API_REPORTS_COMMENTS_UPDATE = 'API_REPORTS_COMMENTS_UPDATE'
export const API_REPORTS_COMMENTS_DESTROY = 'API_REPORTS_COMMENTS_DESTROY'
export const API_REPORTS_REVIEW_STATUSES_UPDATE = 'API_REPORTS_REVIEW_STATUSES_UPDATE'
export const API_REPORTS_REVIEWER_REQUESTS_CREATE = 'API_REPORTS_REVIEWER_REQUESTS_CREATE'
export const API_REPORTS_REVIEWER_REMOVALS_CREATE = 'API_REPORTS_REVIEWER_REMOVALS_CREATE'
export const API_REPORTS_FOLLOW_UP_LABELS_UPDATE = 'API_REPORTS_FOLLOW_UP_LABELS_UPDATE'
export const API_REPORTS_FOLLOW_UP_LABELS_INDEX = 'API_REPORTS_FOLLOW_UP_LABELS_INDEX'
export const FETCH_RELATED_REPORTS = 'FETCH_RELATED_REPORTS'
export const API_REPORTS_ACTIVITY_INDEX = 'API_REPORTS_ACTIVITY_INDEX'
export const SET_EDIT_MODE = 'SET_EDIT_MODE'
export const SHOW_COMMENT_UPDATE_ERROR = 'SHOW_COMMENT_UPDATE_ERROR'
export const SHOW_COMMENT_CREATE_ERROR = 'SHOW_COMMENT_CREATE_ERROR'

export const REPORT_ACTION_OPTIONS = { docType: DOC_TYPES.report }
export const CERTIFICATE_ACTION_OPTIONS = { docType: DOC_TYPES.certificate }

const createOperation = (actionCreator, operationId) => {
  actionCreator.operationId = operationId
  return actionCreator
}

export const fetchReport = (reportId: number, options: ActionOptions = REPORT_ACTION_OPTIONS) =>
  ({ type: FETCH_REPORT, reportId, options })

export const clearReportData = () =>
  ({ type: CLEAR_REPORT_DATA })

export const clearReportErrors = (reportId: number) =>
  ({ type: CLEAR_REPORT_ERRORS, reportId })

export const setReportCommentState = (commentState: CommentState) => {
  return {
    type: SET_REPORT_COMMENT_STATE,
    commentState
  }
}

export const clearReportCommentState = () => ({ type: CLEAR_REPORT_COMMENT_STATE })

export const updateReportFollowUpLabel = createOperation(
  (
    reportId: number,
    customFollowUpLabelId: string,
    labelNote: string
  ) => ({
    type: API_REPORTS_FOLLOW_UP_LABELS_UPDATE,
    reportId,
    customFollowUpLabelId,
    labelNote
  }),
  API_REPORTS_FOLLOW_UP_LABELS_UPDATE
)

export const createReportAttachment = createOperation(
  (reportId: number, attachment: Object) => ({ type: API_REPORTS_ATTACHMENTS_CREATE, reportId, attachment }),
  API_REPORTS_ATTACHMENTS_CREATE
)

export const updateReportAttachment = createOperation(
  (reportId: number, attachment: Object) => ({ type: API_REPORTS_ATTACHMENTS_UPDATE, reportId, attachment }),
  API_REPORTS_ATTACHMENTS_UPDATE
)

export const deleteReportAttachment = createOperation(
  (reportId: number, attachment: Object) => ({ type: API_REPORTS_ATTACHMENTS_DESTROY, reportId, attachment }),
  API_REPORTS_ATTACHMENTS_DESTROY
)

export const createReportComment = createOperation(
  (reportId: number, comment: Object) => ({ type: API_REPORTS_COMMENTS_CREATE, reportId, comment: normalizeCommentText(comment) }),
  API_REPORTS_COMMENTS_CREATE
)

export const updateReportComment = createOperation(
  (reportId: number, comment: Object) => ({ type: API_REPORTS_COMMENTS_UPDATE, reportId, comment }),
  API_REPORTS_COMMENTS_UPDATE
)

export const deleteReportComment = createOperation(
  (reportId: number, comment: Object) => ({ type: API_REPORTS_COMMENTS_DESTROY, reportId, comment }),
  API_REPORTS_COMMENTS_DESTROY
)

export const createReportReviewerRequest = createOperation(
  (reportId: number, userIds: Array<number>) => ({ type: API_REPORTS_REVIEWER_REQUESTS_CREATE, reportId, userIds }),
  API_REPORTS_REVIEWER_REQUESTS_CREATE
)

export const createReportReviewerRemoval = createOperation(
  (reportId: number, userId: number) => ({ type: API_REPORTS_REVIEWER_REMOVALS_CREATE, reportId, userId }),
  API_REPORTS_REVIEWER_REMOVALS_CREATE
)

export const updateReportReviewStatus = createOperation(
  (reportId: number, reviewStatus: string) => ({ type: API_REPORTS_REVIEW_STATUSES_UPDATE, reportId, reviewStatus }),
  API_REPORTS_REVIEW_STATUSES_UPDATE
)

export const fetchRelatedReports = () => ({ type: FETCH_RELATED_REPORTS })

export const fetchReportCustomFollowUpLabels = (reportId: string) =>
  ({ type: API_REPORTS_FOLLOW_UP_LABELS_INDEX, reportId })

export const fetchReportActivity = (newOffset: number = 0) =>
  ({ type: API_REPORTS_ACTIVITY_INDEX, newOffset })

export const setEditMode = (isEditing: boolean, commentId: ?string) => ({ type: SET_EDIT_MODE, payload: { isEditing, commentId } })
export const setShowCommentUpdateError = (value: boolean) => ({ type: SHOW_COMMENT_UPDATE_ERROR , payload: {value} })
export const setShowCommentCreateError = (value: boolean) => ({ type: SHOW_COMMENT_CREATE_ERROR , payload: {value} })
