// @flow
import { unionBy } from 'lodash'
import * as actions from './actions'
import { success, failure } from 'utilities/actions'
import type { Report, CustomFollowUpLabel, ReportActivity } from 'types'

export type State = {
  data?: Report,
  relatedReports?: Report[],
  errors?: Object[],
  commentCreateErrors?: Object[],
  commentUpdateErrors?: Object[],
  editMode: boolean,
  showCommentUpdateError: boolean,
  showCommentCreateError: boolean,
  editCommentId?: string,
  commentState?: {
    reportId?: number,
    text?: string,
    measurands?: string[],
    commentFilter?: string
  },
  customFollowUpLabels: CustomFollowUpLabel[],
  activity: ReportActivity[],
  activityCount?: number,
  activityPagination: {
    limit: number,
    offset: number
  }
}

export const initialState = {
  activityPagination: {
    limit: 100,
    offset: 0
  },
  customFollowUpLabels: [],
  activity: [],
  editMode: false,
  showCommentCreateError: false,
  showCommentUpdateError: false,
}

const reducer = (state: State, action: Object) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case actions.CLEAR_REPORT_DATA:
    case actions.FETCH_REPORT:
      return initialState
    case actions.SHOW_COMMENT_CREATE_ERROR:
      return { ...state, showCommentCreateError: action.payload.value }
    case actions.SHOW_COMMENT_UPDATE_ERROR:
      return { ...state, showCommentUpdateError: action.payload.value }
    case actions.SET_EDIT_MODE:
      const { isEditing, commentId } = action.payload
      return { ...state, editMode: isEditing, editCommentId: commentId }
    case success(actions.API_REPORTS_ATTACHMENTS_UPDATE):
      const { attachments: currentAttachments } = state.data || {}
      const { attachments: newAttachments } = action.data
      const mergedAttachments = unionBy(newAttachments, currentAttachments, 'id')

      return {
        ...state,
        data: {
          ...state.data,
          attachments: mergedAttachments
        }
      }
    case success(actions.FETCH_REPORT):
    case success(actions.API_REPORTS_ATTACHMENTS_DESTROY):
    case success(actions.API_REPORTS_COMMENTS_DESTROY):
    case success(actions.API_REPORTS_REVIEWER_REQUESTS_CREATE):
    case success(actions.API_REPORTS_REVIEWER_REMOVALS_CREATE):
    case success(actions.API_REPORTS_REVIEW_STATUSES_UPDATE):
      return { ...state, data: action.data }
    case success(actions.API_REPORTS_COMMENTS_CREATE):
      return { ...state, data: action.data, showCommentCreateError: false, commentCreateErrors: [] }
    case success(actions.API_REPORTS_COMMENTS_UPDATE):
      return { ...state, data: action.data, editMode: false, showCommentUpdateError: false, commentUpdateErrors: [] }
    case success(actions.API_REPORTS_FOLLOW_UP_LABELS_UPDATE):
      return { ...state, data: action.data }
    case success(actions.FETCH_RELATED_REPORTS):
      return { ...state, relatedReports: action.data }
    case success(actions.API_REPORTS_FOLLOW_UP_LABELS_INDEX):
      const { data: customFollowUpLabels = [] } = action
      return { ...state, customFollowUpLabels }
    case actions.API_REPORTS_ACTIVITY_INDEX:
      const { newOffset } = action
      return {
        ...state,
        activityPagination: { ...state.activityPagination, offset: newOffset }
      }
    case success(actions.API_REPORTS_ACTIVITY_INDEX):
      const { data: activity = [], meta: { activityCount } } = action
      return { ...state, activity, activityCount }
    case failure(actions.FETCH_REPORT):
    case failure(actions.API_REPORTS_ATTACHMENTS_CREATE):
    case failure(actions.API_REPORTS_ATTACHMENTS_UPDATE):
    case failure(actions.API_REPORTS_ATTACHMENTS_DESTROY):
    case failure(actions.API_REPORTS_COMMENTS_DESTROY):
    case failure(actions.API_REPORTS_REVIEWER_REQUESTS_CREATE):
    case failure(actions.API_REPORTS_REVIEWER_REMOVALS_CREATE):
    case failure(actions.API_REPORTS_REVIEW_STATUSES_UPDATE):
    case failure(actions.API_REPORTS_FOLLOW_UP_LABELS_INDEX):
    case failure(actions.API_REPORTS_ACTIVITY_INDEX):
      return { ...state, errors: action.errors }
    case failure(actions.API_REPORTS_COMMENTS_CREATE):
      return { ...state, errors: action.errors, showCommentCreateError: true, commentCreateErrors: action.errors }
    case failure(actions.API_REPORTS_COMMENTS_UPDATE):
      return { ...state, errors: action.errors, editMode: true, showCommentUpdateError: true, commentUpdateErrors: action.errors }
    case failure(actions.API_REPORTS_FOLLOW_UP_LABELS_UPDATE):
      return { ...state, errors: action.errors }
    case actions.CLEAR_REPORT_ERRORS:
      return { ...state, errors: undefined }
    case actions.SET_REPORT_COMMENT_STATE:
      return { ...state, commentState: { ...state.commentState, ...action.commentState } }
    case actions.CLEAR_REPORT_COMMENT_STATE:
      const { commentState: deleted, ...newState } = state
      return newState
    default:
      return state
  }
}

export default reducer
