// @flow
import { failure, success } from '../../utilities/actions'
import {
  REPORT_REVIEW_CHART_DATA_LOAD,
  RESULT_ENTRY_CHART_DATA_LOAD,
} from './actions'
import { prepareReportReviewChartData, prepareResultEntryChartData } from './helpers'

import type {
  Action,
  DashboardState,
  ReportCountItem,
  RoundCountItem,
} from './types'

const emptyRoundCount:Array<RoundCountItem> = []
const emptyReportCount:Array<ReportCountItem> = []

export const initialState: DashboardState = {
  resultEntryChart: {
    isLoading: true,
    data: emptyRoundCount,
    errors: [],
  },
  reportReviewChart: {
    isLoading: true,
    data: emptyReportCount,
    errors: [],
  },
}

const reducer = (state: DashboardState = initialState, action: Action) => {
  let chartData
  let errors
  switch (action.type) {
    case RESULT_ENTRY_CHART_DATA_LOAD:

      return {
        ...state,
        resultEntryChart: {
          ...initialState.resultEntryChart,
          dateRange: action.dateRange,
        }
      }

    case success(RESULT_ENTRY_CHART_DATA_LOAD):

      chartData = prepareResultEntryChartData({ data: action.data })
      return {
        ...state,
        resultEntryChart: {
          ...state.resultEntryChart,
          data: chartData,
          errors: [],
          isLoading: false,
        }
      }

    case failure(RESULT_ENTRY_CHART_DATA_LOAD):
      errors = action.errors
      return {
        ...state,
        resultEntryChart: {
          ...state.resultEntryChart,
          data: [],
          errors: errors,
          isLoading: false,
        }
      }

    case REPORT_REVIEW_CHART_DATA_LOAD:

      return {
        ...state,
        reportReviewChart: {
          ...initialState.reportReviewChart,
          dateRange: action.dateRange,
        }
      }

    case success(REPORT_REVIEW_CHART_DATA_LOAD):
      chartData = prepareReportReviewChartData({ data: action.data })
      return {
        ...state,
        reportReviewChart: {
          ...state.reportReviewChart,
          data: chartData,
          isLoading: false
        }
      }

    case failure(REPORT_REVIEW_CHART_DATA_LOAD):
      errors = action.errors
      return {
        ...state,
        reportReviewChart: {
          ...state.reportReviewChart,
          data: [],
          errors: errors,
          isLoading: false,
        }
      }
    default:
      return state
  }
}

export default reducer