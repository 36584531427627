// @flow
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './rootReducer'
import * as rootActions from './rootActions'
import rootSaga from './rootSaga'
import StateLoader from 'utilities/stateLoader'
import AppMonitoring from 'utilities/monitoring'

const sagaMiddleware = createSagaMiddleware({
  onError: AppMonitoring.noticeError,
})

const store = createStore(
  rootReducer,
  { auth: StateLoader.getAuth() },
  composeWithDevToolsDevelopmentOnly(applyMiddleware(
    sagaMiddleware
  ))
)

sagaMiddleware.run(rootSaga)

store.subscribe(() => {
  StateLoader.saveAuth(store.getState().auth)
})

const { auth } = store.getState()
store.dispatch(rootActions.appInitialization(auth))

export default store
