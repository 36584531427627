// @flow

import { DEFAULT_FEATURE_TOURS } from 'utilities/featureTour'
import { CLIENT_SETTING_NAMESPACES, groupedClientSettingsToObject } from 'utilities/clientSettings'
import { useClientSettings } from 'hooks/clientSettings'

const useFeatureTourSettings = (featureKey: string) => {
  const settingKey = `${CLIENT_SETTING_NAMESPACES.featureTours}.${featureKey}`
  const { updateSettings, allSettings } = useClientSettings(settingKey)
  const featureTourSettings = {
    ...DEFAULT_FEATURE_TOURS,
    ...(groupedClientSettingsToObject(allSettings, CLIENT_SETTING_NAMESPACES.featureTours) || {}),
  }

  return {
    featureTourSettings,
    updateFeatureTourSettings: updateSettings,
  }
}

export default useFeatureTourSettings
