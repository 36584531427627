// @flow
import React, { Fragment, useEffect, useState } from 'react'
import Tour from 'components/molecules/tour'
import TourAlert from 'components/molecules/tourAlert'
import { setSteps, tourAlertViewed } from './helpers'
import type { TourStep } from 'types'
import useFeatureTourSettings from 'hooks/featureTours/useFeatureTourSettings'

type Props = {
  feature: string,
  steps: TourStep[],
  message?: string,
  showTourAlert?: boolean,
}

const TourTemplate = (props: Props) => {
  const { feature = '', steps, message, showTourAlert } = props
  const { featureTourSettings: featureTours, updateFeatureTourSettings: updateFeatureTour } =
    useFeatureTourSettings(feature)
  const [run, setRun] = useState(false)

  const featureTour = featureTours[feature]

  const shouldRun = !showTourAlert || tourAlertViewed({ featureTour })

  useEffect(() => {
    setRun(shouldRun)
  }, [shouldRun])

  if (!featureTours) return null
  if (Object.keys(featureTours).length === 0) return null

  const stepsArr = setSteps({ feature, featureTour: featureTours[feature], steps, run: shouldRun })
  const handleStart = () => setRun(true)

  return (
    <Fragment>
      {
        showTourAlert && !run &&
        <TourAlert
          feature={feature}
          featureTours={featureTours}
          message={message}
          onStart={handleStart}
          updateFeatureTour={updateFeatureTour}
          steps={stepsArr}
        />
      }
      <Tour
        feature={feature}
        featureTours={featureTours}
        run={run}
        steps={stepsArr}
        updateFeatureTour={updateFeatureTour}
      />
    </Fragment>
  )
}

export default TourTemplate
