import React, { useState } from 'react'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import useCookieSettings from 'hooks/cookies/useCookieSettings'
import Alert from '@mui/material/Alert'
import AcceptingCookiesModal from './modal'

const useStyles = makeStyles((theme) => ({
  alert: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 999999999,
    color: theme.palette.common.white,
  },
  manageButton: {
    textDecoration: 'underline',
  },
}))

const AcceptingCookiesAlert = () => {
  const classes = useStyles()
  const { submitting, cookiesAccepted, updateCookiesSetting } = useCookieSettings()
  const [open, setOpen] = useState(false)

  if (cookiesAccepted === true || cookiesAccepted === false) return null

  return (
    <>
      <AcceptingCookiesModal
        open={open}
        acceptCookies={(value) => updateCookiesSetting(value)}
        submitting={submitting}
      />
      <Alert
        className={classes.alert}
        variant='filled'
        severity='info'
        action={
          <>
            <Button
              color='inherit'
              size='small'
              className={classes.manageButton}
              onClick={() => setOpen(true)}
              disabled={submitting}
            >
              Manage
            </Button>
            <Button
              variant='outlined'
              color='inherit'
              size='small'
              onClick={() => updateCookiesSetting(true)}
            >
              Got it!
            </Button>
          </>
        }
      >
        This site uses cookies to provide you with a great user experience. By using the RCPAQAP
        website, you accept our use of cookies.
      </Alert>
    </>
  )
}

export default AcceptingCookiesAlert
