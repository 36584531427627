// @flow
import React, { Component } from 'react'
import Joyride, { ACTIONS, EVENTS } from 'react-joyride'

import TourTooltip from 'components/molecules/tourTooltip'
import { getUnseenSteps } from './helpers'
import { stepsStatusHash } from 'utilities/stepsStatusHash'

type Props = {
  feature: string,
  featureTours: Object,
  run: boolean,
  steps: Array<Object>,
  updateFeatureTour: <T>(data: T) => void
}

class Tour extends Component<Props> {
  static defaultProps = {
    run: true
  }

  handleCallback = ({ type, action }: Object) => {
    const { steps, updateFeatureTour } = this.props
    let newStatus

    if (action === ACTIONS.SKIP) {
      newStatus = 'dismissed'
    } else if (type === EVENTS.TOUR_END) {
      newStatus = 'viewed'
    }

    if (!newStatus) return

    updateFeatureTour<{[string]: string}>(stepsStatusHash({ newStatus, steps }))
  }

  render () {
    const { steps } = this.props
    const unseenSteps = getUnseenSteps({ steps })
    if (unseenSteps.length === 0) return null

    return (
      <Joyride
        continuous
        showProgress
        showSkipButton
        disableOverlayClose
        run={this.props.run}
        locale={{ last: 'Done' }}
        tooltipComponent={TourTooltip}
        scrollOffset={100}
        styles={{
          options: {
            overlayColor: 'rgba(0, 0, 0, 0.2)'
          }
        }}
        floaterProps={{
          styles: {
            arrow: {
              length: 8,
              spread: 16
            }
          }
        }}
        steps={unseenSteps}
        callback={this.handleCallback}
      />
    )
  }
}

export default Tour
