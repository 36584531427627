// @flow
import { combineReducers } from 'redux'
// $FlowFixMe
import { loadingBarReducer } from 'react-redux-loading-bar'
import type { Action, AppState } from './types'

import auth from './auth'
import * as authActions from './auth/actions'
import api from './api'
import customFollowUpLabels from './customFollowUpLabels'
import dashboard from './dashboard'
import dataExtractRequest from './dataExtractRequest'
import salesforcePortalTabs from './salesforcePortalTabs'
import globalNotification from './globalNotification'
import memberships from './memberships'
import nextAndPreviousReportIds from './nextAndPreviousReportIds'
import reportAnalyticsDetails from './reportAnalyticsDetails'
import organisations from './organisations'
import passwordReset from './passwordReset'
import programEvents from './programEvents'
import report from './report'
import reports from './reports'
import reportsOverview from './reportsOverview'
import request from './request'
import roundEnrolment from './roundEnrolment'
import roundEnrolments from './roundEnrolments'
import roundEnrolmentsOverview from './roundEnrolmentsOverview'
import signup from './signup'
import uploads from './uploads'
import notificationPreference from './notificationPreference'
import calendarPreference from './calendarPreference'
import generalPreference from './generalPreference'
import accountPreference from './accountPreference'

const appReducer: (state: ?Object, action: Action) => AppState = combineReducers({
  auth,
  api,
  customFollowUpLabels,
  dashboard,
  dataExtractRequest,
  salesforcePortalTabs,
  globalNotification,
  memberships,
  nextAndPreviousReportIds,
  organisations,
  passwordReset,
  programEvents,
  report,
  reports,
  reportsOverview,
  request,
  roundEnrolment,
  roundEnrolments,
  roundEnrolmentsOverview,
  reportAnalyticsDetails,
  loadingBar: loadingBarReducer,
  signup,
  uploads,
  notificationPreference,
  calendarPreference,
  generalPreference,
  accountPreference,
})

const rootReducer = (state: ?Object, action: Action): AppState => {
  if (action.type === authActions.LOGOUT_USER) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
