// @flow
import { snakeCase, toUpper } from 'lodash'

const REPORT_REVIEW_CHART_DATA_LOAD = 'REPORT_REVIEW_CHART_DATA_LOAD'
const RESULT_ENTRY_CHART_DATA_LOAD = 'RESULT_ENTRY_CHART_DATA_LOAD'

const loadData = (category: string, dateRange: Object) => ({
  type: `${toUpper(snakeCase(category))}_DATA_LOAD`,
  dateRange
})

export {
  REPORT_REVIEW_CHART_DATA_LOAD,
  RESULT_ENTRY_CHART_DATA_LOAD,
  loadData,
}
