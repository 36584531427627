// @flow
import { REPORT_REVIEW_STATUSES, RESULT_ENTRY_STATUSES } from 'qap/constants'
import type {
  ChartDataType,
  ReportCountDataType,
  ReportCountItem,
  RoundCountDataType,
  RoundCountItem,
} from './types'

const tallyStats = (data, filter): number => {
  // Flow does not yet support method or property calls in optional chains.
  // $FlowFixMe
  return data?.filter(filter)?.reduce((acc, s) => acc + s.count, 0) || 0
}

export const prepareResultEntryChartData = ({ data }: RoundCountDataType): Array<ChartDataType> => {
  const missedCount = tallyStats(data, (s: RoundCountItem) => s.status === RESULT_ENTRY_STATUSES.MISSED.value)
  const lateCount = tallyStats(data, (s: RoundCountItem) => s.status === RESULT_ENTRY_STATUSES.LATE.value && s.lateSubmission === true)
  const submittedCount = tallyStats(data, (s: RoundCountItem) => s.status === RESULT_ENTRY_STATUSES.SUBMITTED.value && !!s.lateSubmission === false)

  return [
    { name: RESULT_ENTRY_STATUSES.MISSED.label, value: missedCount },
    { name: RESULT_ENTRY_STATUSES.LATE.label, value: lateCount },
    { name: RESULT_ENTRY_STATUSES.SUBMITTED.label, value: submittedCount },
  ]
}

export const prepareReportReviewChartData = ({ data }: ReportCountDataType): Array<ChartDataType> => {
  const notReviewedCount = tallyStats(data, (s: ReportCountItem) => s.reviewStatus === REPORT_REVIEW_STATUSES.NOT_REVIEWED.value && !s.fresh)
  const inProgressCount = tallyStats(data, (s: ReportCountItem) => s.reviewStatus === REPORT_REVIEW_STATUSES.IN_PROGRESS.value)
  const doneCount = tallyStats(data, (s: ReportCountItem) => s.reviewStatus === REPORT_REVIEW_STATUSES.DONE.value)
  const signedOffCount = tallyStats(data, (s: ReportCountItem) => s.reviewStatus === REPORT_REVIEW_STATUSES.SIGNED_OFF.value)

  return [
    { name: REPORT_REVIEW_STATUSES.NOT_REVIEWED.label, value: notReviewedCount },
    { name: REPORT_REVIEW_STATUSES.IN_PROGRESS.label, value: inProgressCount },
    { name: REPORT_REVIEW_STATUSES.DONE.label, value: doneCount },
    { name: REPORT_REVIEW_STATUSES.SIGNED_OFF.label, value: signedOffCount },
  ]
}